
<template>
  <div class="p-0 m-0 hidden-print-only">
    <!-- <v-container>
      <v-btn text @click="showTabs = true">
        <v-icon> mdi-filter </v-icon> Filtro
      </v-btn>

      <v-btn
        class="hidden-print-only btn-block"
        @click="abrirMapa"
        text
        style="text-align: left"
      >
        <v-icon class="v-icon--large"> mdi-map </v-icon>
      </v-btn>

      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" style="text-align: left" text>
            <v-icon class="v-icon--large"> mdi-dots-vertical </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            @click="$router.push({ name: 'relatoriooportunidades' })"
          >
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Oportunidades</v-list-item-title>
          </v-list-item>

          <v-list-item @click="$router.push({ name: 'relatorioqualificados' })">
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Qualificados</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push({ name: 'relatorioagenda' })">
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Agenda</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push({ name: 'relatorioEstrelas' })">
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Estrelas</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$router.push({ name: 'relatoriomaster' })">
            <v-list-item-icon>
              <v-icon>mdi-file-document</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Clientes</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar size="64px">
              <v-img :src="djangoUrl + getAvatar" v-if="getAvatar"></v-img>
              <v-icon v-else large>mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
          <span class="mx-5">
            {{ perfil.first_name }} {{ perfil.last_name }}</span
          >
        </template>
        <v-list>
          <v-list-item link @click="goTo({ name: 'perfil' })">
            <v-list-item-icon>
              <v-icon>mdi-card-account-details</v-icon>
            </v-list-item-icon>
            Perfil
          </v-list-item>
          <v-list-item link @click="changeTheme">
            <v-list-item-icon>
              <v-icon>mdi-palette</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Tema: {{ this.$vuetify.theme.dark ? "Escuro" : "Claro" }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item link @click="logoutnow">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sair</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-container> -->
    <v-dialog v-model="showTabs" persistent width="80%" offset-y>
      <v-card>
        <div class="mx-3">
          <v-card-title class="m-3">
            Filtros
            <v-spacer></v-spacer>
            <v-btn icon @click="showTabs = false">
              <v-icon> mdi-window-close </v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-row class="p-0 m-0">
              <v-col>
                <v-row class="p-1 m-1">
                  <v-col cols="12" md="7" class="py-0">
                    <v-text-field
                      v-model="filtro.nome"
                      label="Cliente"
                      @keyup.enter.exact="pesquisaFunil"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="5" class="py-0">
                    <v-text-field
                      v-model="filtro.protocolo"
                      label="Protocolo"
                      @keyup.enter.exact="pesquisaFunil"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col class="group-border">
                    <v-row>
                      <v-col cols="12" md="3" class="p-1 m-1 pb-0">
                        <v-text-field
                          v-model="filtro.dataInicio"
                          label="Data Inicial"
                          outlined
                          type="date"
                          :disabled="pesquisaNome"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="p-1 m-1 pb-0">
                        <v-text-field
                          v-model="filtro.dataFim"
                          label="Data Final"
                          outlined
                          type="date"
                          :disabled="pesquisaNome"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="p-1 m-1 pb-0">
                        <v-autocomplete
                          v-model="filtro.vendedoresId"
                          :items="getUsuariosSelect"
                          label="Vendedor"
                          multiple
                          chips
                          small-chips
                          hide-selected
                          outlined
                          prepend-inner-icon="mdi-account"
                          deletable-chips
                          :disabled="pesquisaNome"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3" class="p-1 m-1 pb-0">
                        <v-autocomplete
                          v-model="filtro.responsavelId"
                          :items="getUsuariosSelect"
                          label="Responsável"
                          multiple
                          chips
                          small-chips
                          hide-selected
                          outlined
                          deletable-chips
                          prepend-inner-icon="mdi-account"
                          :input-attrs="{ autocomplete: 'off' }"
                          :disabled="pesquisaNome"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" class="p-1 m-1 pb-0">
                        <v-autocomplete
                          v-model="filtro.etapas"
                          :items="getEtapasSelect"
                          label="Etapas"
                          multiple
                          chips
                          small-chips
                          hide-selected
                          outlined
                          prepend-inner-icon="mdi-target"
                          deletable-chips
                          :disabled="pesquisaNome"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" class="p-1 m-1 pb-0">
                        <v-autocomplete
                          v-model="filtro.segmentacaoId"
                          :items="getSegmentacaoSelect"
                          label="Segmentação"
                          multiple
                          chips
                          small-chips
                          hide-selected
                          outlined
                          required
                          deletable-chips
                          prepend-inner-icon="mdi-map-marker"
                          :input-attrs="{ autocomplete: 'off' }"
                          :disabled="pesquisaNome"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="4" class="p-1 m-1 pb-0">
                        <v-autocomplete
                          v-model="filtro.unidadeId"
                          :items="getUnidadeSelect"
                          label="Unidades"
                          multiple
                          chips
                          small-chips
                          hide-selected
                          outlined
                          required
                          deletable-chips
                          prepend-inner-icon="mdi-target"
                          :input-attrs="{ autocomplete: 'off' }"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="6" class="p-1 m-1 pb-0">
                        <v-text-field
                          label="Logradouro"
                          outlined
                          prepend-inner-icon="mdi-road-variant"
                          name="logradouro"
                          v-model="filtro.logradouro"
                          :disabled="pesquisaNome"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="p-1 m-1 pb-0">
                        <v-text-field
                          label="Número"
                          prepend-inner-icon="mdi-numeric"
                          outlined
                          v-model="filtro.numero"
                          :disabled="pesquisaNome"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="p-1 m-1 pb-0">
                        <v-text-field
                          v-model="filtro.bairro"
                          label="Bairro"
                          outlined
                          prepend-inner-icon="mdi-map-marker"
                          :disabled="pesquisaNome"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" class="p-1 m-1 pb-0">
                        <v-autocomplete
                          v-model="filtro.cidadesId"
                          :items="getCidadesSelect"
                          label="Cidade"
                          multiple
                          chips
                          small-chips
                          hide-selected
                          outlined
                          deletable-chips
                          prepend-inner-icon="mdi-map-marker"
                          :input-attrs="{ autocomplete: 'off' }"
                          @click:prepend-inner="
                            $store.dispatch('setDialogCidades', true)
                          "
                          :disabled="pesquisaNome"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3" class="p-1 m-1 pb-0">
                        <v-autocomplete
                          v-model="filtro.estadosId"
                          :items="getEstadosSelect"
                          label="Estado"
                          multiple
                          chips
                          small-chips
                          hide-selected
                          outlined
                          deletable-chips
                          prepend-inner-icon="mdi-map"
                          @input="loadCidades"
                          :input-attrs="{ autocomplete: 'off' }"
                          :disabled="pesquisaNome"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3" class="p-1 m-1 pb-0">
                        <v-autocomplete
                          v-model="filtro.origemId"
                          :items="getOrigemSelect"
                          label="Origem"
                          multiple
                          chips
                          small-chips
                          hide-selected
                          outlined
                          prepend-inner-icon="mdi-source-branch"
                          deletable-chips
                          :disabled="pesquisaNome"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" md="3" class="p-1 m-1 pb-0">
                        <v-autocomplete
                          v-model="filtro.oportunidades"
                          :items="getSegmentoAlvoSelect"
                          label="Oportunidades"
                          multiple
                          chips
                          small-chips
                          hide-selected
                          outlined
                          prepend-inner-icon="mdi-target"
                          deletable-chips
                          :disabled="pesquisaNome"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" class="p-1 m-1">
                    <v-btn
                      color="success"
                      class="white--text"
                      @click="pesquisar"
                      block
                      ><v-icon left>mdi-search</v-icon>Pesquisar</v-btn
                    >
                  </v-col>
                  <v-col cols="12" md="6" class="p-1 m-1">
                    <v-btn
                      color="light"
                      class="dark--text"
                      @click="limparFiltro"
                      block
                      ><v-icon left>mdi-close</v-icon>Limpar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import "../plugins/efeitos";

export default {
  name: "Filtro",
  data: () => ({
    pesquisaNome: false,
    avatar: null,
    showMap: false,
    menuItems: [],
    perfil: {
      is_superuser: false,
      username: "",
      first_name: "",
      last_name: "",
      email: "",
      is_staff: false,
      is_active: false,
      date_joined: "",
      last_login: "",
      first_login: false,
    },
    filtro: {
      nome: "",
      dataInicio: "",
      dataFim: "",
      segmentacaoId: [],
      responsavelId: [],
      estadosId: [],
      cidadesId: [],
      vendedoresId: [],
      unidadeId: [],
      bairro: "",
      origemId: [],
      oportunidades: [],
      etapas: [],
      logradouro: "",
      numero: "",
      protocolo: "",
    },
    showTabs: false,
    djangoUrl: process.env.VUE_APP_DJANGO_URL,
  }),
  computed: {
    ...mapGetters([
      "getUnidadeSelect",
      "getEstadosSelect",
      "getCidadesSelect",
      "getSegmentacaoSelect",
      "getUsuariosSelect",
      "getAvatar",
      "getOrigemSelect",
      "getSegmentoAlvoSelect",
      "getEtapasSelect",
      "getSegmentacaoNameById",
      "getUsuarioNameById",
      "getEstadoNameById",
      "getCidadeNameById",
      "getUnidadeNameById",
      "getOrigemNameById",
      "getSegmentoAlvoNameById",
      "getEtapaNameById",
      "getFiltroPesquisa",
      "getClienteFiltro",
    ]),
    avatarImage() {
      this.getUserAvatar();
      return this.avatar === undefined
        ? "https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
        : this.avatar;
    },
    isChanged() {
      return (
        this.perfil.username !== "" &&
        this.perfil.first_name !== "" &&
        this.perfil.last_name !== "" &&
        this.perfil.email !== ""
      );
    },
  },
  methods: {
    ...mapActions(["updateShowMap"]),
    // // abrirMapa() {
    // //   this.showMap = !this.showMap;
    // //   this.updateShowMap(this.showMap); // Altera o valor de showMap na store
    // // },
    // // changeTheme() {
    // //   this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    // // },
    // // logoutnow() {
    // //   // this.$store.dispatch("logout");
    // //   localStorage.clear();
    // //   sessionStorage.clear();
    // //   this.$router.push({ path: "/login" });
    // // },
    // // async getPerfil() {
    // //   try {
    // //     if (this.$store.state.index.user.user.user_id === undefined) {
    // //       return;
    // //     }
    // //     this.user = await this.$store.state.index.user.user.user_id;
    // //     // console.log("user", this.user);

    // //     let response = await this.$http.get("user/detail/" + this.user + "/");
    // //     this.perfil = response.data;
    // //     Vue.use(require("vue-moment"));
    // //     this.perfil.date_joined = this.$moment(this.perfil.date_joined).format(
    // //       "DD/MM/YYYY HH:mm:ss"
    // //     );
    // //     this.perfil.last_login = this.$moment(this.perfil.last_login).format(
    // //       "DD/MM/YYYY HH:mm:ss"
    // //     );
    // //     if (this.perfil.first_login) {
    // //       this.$router.push({ name: "perfil" }).catch(() => {});
    // //     }
    // //   } catch (error) {
    // //     // console.log("user", "erro 4");
    // //     //redirect
    // //     this.$router.push({ name: "login" }).catch(() => {});
    // //     // console.log(error);
    // //   }
    // // },
    // // async getIcon() {
    // //   // store avatar $store
    // //   let img = this.$store.getters.getAvatar;
    // //   if (img) {
    // //     this.avatar = img;
    // //   } else {
    // //     try {
    // //       let idUser = this.$store.state.index.user.user.user_id;
    // //       let response = await this.$http.get("user/image/" + idUser + "/");
    // //       // console.log(idUser);
    // //       // this.clientes = response.data;
    // //       // console.log(response.data.avatar);
    // //       // return response.data.avatar;
    // //       this.avatar = response.data.avatar;
    // //     } catch (error) {
    // //       //redirect
    // //       // this.$router.push({ name: "login" });
    // //       console.log(error);
    // //     }
    // //   }
    // // },
    // // async getUserAvatar() {
    // //   if (this.$store.getters.getUser.user == undefined) {
    // //     return;
    // //   }
    // //   try {
    // //     let idUser = await this.$store.state.index.user.user.user_id;
    // //     let response = await this.$http.get("user/image/" + idUser + "/");
    // //     // this.$store.commit("setUser", response.data);
    // //     // this.$store.dispatch("setAvatar", response.data.avatar);
    // //     this.avatar = response.data.avatar;
    // //     // console.log(this.avatar);
    // //   } catch (error) {
    // //     console.log(error);
    // //   }
    // // },
    // // goTo(route) {
    // //   this.$router.push(route).catch(() => {});
    // // },
    // selectItem(item) {
    //   console.log("Item selecionado:", item);
    // },
    openDialog() {
      this.showTabs = true;
    },
    atualizarFiltro() {
      const filtroEmail = this.$store.getters.getClienteFiltro;
      if (filtroEmail == null) {
        if (this.filtro.dataFim === "") {
          const today = new Date();
          today.setDate(today.getDate() + 1); // Adiciona um dia

          const year = today.getFullYear();
          const month = (today.getMonth() + 1).toString().padStart(2, "0");
          const day = today.getDate().toString().padStart(2, "0");

          this.filtro.dataFim = `${year}-${month}-${day}`;
        }
        if (this.filtro.dataInicio == "") {
          this.filtro.dataInicio = new Date(
            new Date().setDate(new Date().getDate() - 30)
          )
            .toISOString()
            .slice(0, 10);
          this.pesquisaFunil();
        }
      } else {
        this.$http(`clientefiltroemail/?id=${filtroEmail}`).then((resp) => {
          if (resp.status <= 300) {
            this.filtro.nome = resp.data[0].nome;
            this.filtro.unidadeId = [];
            this.pesquisaNome = true;
            this.pesquisaFunil();
            this.pesquisar();
            this.$store.dispatch("setClienteFiltro", null);
          }
        });
      }
    },
    loadCidades(id) {
      let uf = this.getEstadosSelect.find((e) => e.value === id);
      this.$store.dispatch("loadCidades", uf.uf);
    },
    limparFiltro() {
      this.filtro = {
        nome: "",
        dataInicio: "",
        dataFim: "",
        segmentacaoId: [],
        responsavelId: [],
        estadosId: [],
        cidadesId: [],
        unidadeId: [],
        vendedoresId: [],
        bairro: "",
        origemId: [],
        oportunidades: [],
        etapas: [],
        logradouro: "",
        numero: "",
        protocolo: "",
      };
      this.pesquisaFunil();
    },
    pesquisar() {
      this.verificaPaginaAberta();
      this.showTabs = false;
      this.pesquisaFunil();
    },
    pesquisaFunil() {
      if (this.pesquisaNome) {
        const filtroNome = {
          nome: this.filtro.nome,
          unidadeId: this.filtro.unidadeId,
        };
        if(this.filtro.protocolo !== "") {
          filtroNome.protocolo = this.filtro.protocolo;
        }
        this.$store.dispatch("setFiltroPesquisa", filtroNome);
        this.showTextoFiltros(filtroNome);
      } else {
        this.$store.dispatch("setFiltroPesquisa", this.filtro);
        this.showTextoFiltros(this.filtro);
      }
    },
    verificaPaginaAberta() {
      // Somente da dispatch na request da página que está aberta
      switch (this.$route.path) {
        case "/":
          this.pesquisaFunil();
          this.$store.dispatch("loadProspecfunil");
          this.$store.dispatch("loadAgendaFunil");
          this.$store.dispatch("loadDiagnosticoFunil");
          this.$store.dispatch("loadNegociacaoFunil");
          this.$store.dispatch("loadNegociacaoativaFunil");
          break;
        case "/listarProspecDiag":
          this.$store.dispatch("loadDiagnosticos");
          break;
        case "/lstPropostas":
          this.$store.dispatch("loadNegociacoes");
          break;
        case "/lstNegociacoes":
          this.$store.dispatch("loadNegociacoes");
          break;
        case "/negociacoesFinalizadas":
          this.$store.dispatch("loadNegociacoesFinalizadas");
          break;
        case "/listarProspecQuali":
          this.$store.dispatch("loadProspeccoesQualifiedpg", { pg: 1 });
          break;
        case "/listarProspec":
          this.$store.dispatch("loadProspeccoespg", { pg: 1 });
          break;
        case "/agendamento":
          this.$store.dispatch("loadAgendamentos");
          this.$store.dispatch("loadUsuarios");
          this.$store.dispatch("loadTipoAgendamento");
          break;
        case "/relatoriooportunidades":
          this.$store.dispatch("loadReportOportunidades");
          break;
        case "/relatorioqualificados":
          this.$store.dispatch("loadReportOportunidades", {
            qualificados: true,
          });
          break;
        case "/relatorioagenda":
          this.$store.dispatch("loadReportAgendamentos");
          break;
        case "/relatorioestrelas":
          this.$store.dispatch("loadReportOportunidades", { estrelas: true });
          break;
        case "/relatoriomaster":
          this.$store.dispatch("loadReportOportunidades", { master: true });
          break;
        default:
          break;
      }
    },
    showTextoFiltros(filtro) {
      // Percorre os atributos do obj filtro
      let texto = "";
      for (let key in filtro) {
        if (filtro[key] != "") {
          texto += this.getTitle(key, filtro[key]);
        }
      }
      this.$store.dispatch("setTextoFiltro", texto);
    },
    getTitle(key, val) {
      if (Array.isArray(val)) {
        let texto = "";
        let iniTexto = this.getTitle(key, val[0]).split(":")[0];

        val.forEach((element) => {
          texto +=
            this.getTitle(key, element).split(":")[1].split("|")[0] + ", ";
        });
        return `${iniTexto}: ${texto.substring(0, texto.length - 2)} | `;
      }
      switch (key) {
        case "nome":
          return `Razão Social: ${val} | `;
        case "dataInicio":
          return `Data Inicial: ${this.formatarData(val)} | `;
        case "dataFim":
          return `Data Final: ${this.formatarData(val)} | `;
        case "segmentacaoId":
          return "Segmentação: " + this.getSegmentacaoNameById(val) + " | ";
        case "responsavelId":
          return "Responsável: " + this.getUsuarioNameById(val) + " | ";
        case "estadosId":
          return "Estados: " + this.getEstadoNameById(val) + " | ";
        case "cidadesId":
          return "Cidades: " + this.getCidadeNameById(val) + " | ";
        case "vendedoresId":
          return "Vendedores: " + this.getUsuarioNameById(val) + " | ";
        case "unidadeId":
          return "Unidades: " + this.getUnidadeNameById(val) + " | ";
        case "origemId":
          return "Origem: " + this.getOrigemNameById(val) + " | ";
        case "oportunidades":
          return "Oportunidades: " + this.getSegmentoAlvoNameById(val) + " | ";
        case "etapas":
          return "Etapas: " + this.getEtapaNameById(val) + " | ";
        case "bairro":
          return "Bairro: " + val + " | ";
        case "logradouro":
          return "Logradouro: " + val + " | ";
        case "numero":
          return "Número: " + val + " | ";
        case "protocolo":
          return `Protocolo: ${val} | `;
        default:
          return "";
      }
    },
    formatarData(data) {
      let dataFormatada = new Date(data);
      dataFormatada.setHours(dataFormatada.getHours() + 3);
      return dataFormatada.toLocaleDateString("pt-BR");
    },
  },
  mounted() {
    if (this.$route.path != "/login" && this.$route.path != "/") {
      this.$store.dispatch("loadUnidade");
      // this.getPerfil();
    }
  },
  created() {
    if (this.$route.path != "/login" && this.$store.getters.getUser.user) {
      this.$store.dispatch("loadUnidade");
      this.$store.dispatch("loadSegmentacao");
      this.$store.dispatch("loadEstados");
      this.$store.dispatch("loadUsuarios");
      this.$store.dispatch("loadCidades");
      this.$store.dispatch("loadOrigem");
      this.$store.dispatch("loadSegmentoAlvo");
      this.$store.dispatch("loadEtapas");
      // this.$store.dispatch("loadSegmentacao");

      this.atualizarFiltro();
      this.pesquisaFunil();
      // this.getPerfil();
    }
  },

  watch: {
    // getUnidadeSelect: {
    //   handler(newVal) {
    //     if (newVal.length > 0) {
    //       this.filtro.unidadeId = [newVal[0].value];
    //     } else {
    //       this.filtro.unidadeId = [];
    //     }
    //   },
    //   immediate: true,
    // },
    "filtro.nome": function (novoNome) {
      if (novoNome !== "") {
        this.pesquisaNome = true;
      } else if (this.filtro.protocolo === "") {
        this.pesquisaNome = false;
      }
    },
    "filtro.protocolo": function (novoProtocolo) {
      if (novoProtocolo !== "") {
        this.pesquisaNome = true;
      } else if (this.filtro.nome === "") {
        this.pesquisaNome = false;
      }
    },
    getClienteFiltro(value) {
      if (value) {
        this.atualizarFiltro();
      }
    },
  },
};
</script>

<style scoped>
.mao {
  cursor: pointer;
}
.link {
  cursor: pointer;
}
.fundostv {
  /* cor de fundo da página */
  background: rgba(11, 100, 5, 0.5);
  /* cor da fonte */
  color: #fff;
  /* cor da barra de rolagem */
  scrollbar-color: rgba(255, 255, 255, 0.5) rgba(0, 0, 0, 0.5);
  /* cor do fundo da barra de rolagem */
  scrollbar-face-color: rgba(255, 255, 255, 0.5);
  /* cor do fundo da barra de rolagem */
  scrollbar-track-color: rgba(0, 0, 0, 0.5);
}

.group-border {
  border: 1px solid #dcd8d8;
  border-radius: 5px;
  padding: 10px; /* reduce padding */
  margin-bottom: 10px; /* reduce margin */
}
.top-right > .v-dialog__container {
  justify-content: flex-end;
}
</style>